* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  color: #666;
  height: 100%;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8em;
  overflow-x: hidden;
}

header {
  width: 100vw;
}

.pimg1, .pimg2, .pimg3, .pimg4, .pimg5, .pimg6 {
  opacity: .7;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  max-width: 100vw;
  position: relative;
}

.pimg1 {
  background-image: url("Image1.6069629a.jpg");
  min-height: 600px;
}

.pimg3 {
  background-image: url("Image2.8aa6e0e2.jpg");
  min-height: 600px;
}

.pimg6 {
  background-image: url("Image3.3352af1b.jpg");
  min-height: 600px;
}

.pimg2 {
  background-image: url("Image4.50367007.jpg");
  min-height: 600px;
}

.pimg4 {
  background-image: url("Image5.f31562e4.jpg");
  min-height: 600px;
}

.pimg5 {
  background-image: url("Image6.6e5f222b.jpg");
  min-height: 600px;
}

.section {
  text-align: center;
  padding: 50px 80px;
}

.section-light {
  color: #666;
  background-color: #f4f4f4;
}

.section-dark {
  color: #ddd;
  background-color: #282e34;
}

.ptext {
  text-align: center;
  color: #fff;
  letter-spacing: 8px;
  text-transform: uppercase;
  width: 100%;
  font-size: 27px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ptext .border {
  color: #fff;
  background-color: #000000bf;
  padding: 20px;
}

.ptext .border.trans {
  background-color: #0000;
}

nav {
  z-index: 1000;
  background-color: #00000080;
  width: 100%;
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.nav-container {
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.nav-toggle {
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 24px;
  display: none;
}

.logo {
  top: 60px;
  left: center;
  z-index: 1;
  opacity: 1;
  width: 325px;
  height: auto;
  position: relative;
}

nav ul {
  justify-content: space-around;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .3s;
}

nav ul li a:hover {
  background-color: #fff3;
}

@media (width <= 768px) {
  .nav-toggle {
    display: block;
  }

  .nav-menu {
    z-index: 1000;
    background-color: #000c;
    flex-direction: column;
    width: 100%;
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
  }

  .nav-menu.show {
    display: flex;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 10px 0;
  }

  .ptext {
    letter-spacing: 4px;
    font-size: 20px;
  }

  .section {
    padding: 30px 20px;
  }

  .pimg1, .pimg2, .pimg3, .pimg4, .pimg5, .pimg6 {
    background-attachment: scroll;
  }

  .logo {
    z-index: 1;
    opacity: 1;
    width: 225px;
    height: auto;
    position: relative;
    top: 40px;
    left: 120px;
    transform: translateX(-50%);
  }
}

@media (width <= 568px) {
  .pimg1, .pimg2, .pimg3, .pimg4, .pimg5, .pimg6 {
    background-attachment: scroll;
    min-height: 400px;
  }

  .ptext {
    letter-spacing: 2px;
    font-size: 16px;
  }

  .section {
    padding: 20px 10px;
  }

  nav ul li a {
    padding: 10px;
  }
}
/*# sourceMappingURL=index.45c14fbe.css.map */
