/* Reset margins and paddings */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: #666;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

header {
    width: 100vw;
}

.pimg1, .pimg2, .pimg3, .pimg4, .pimg5, .pimg6 {
    position: relative;
    opacity: 0.70;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    max-width: 100vw; /* Ensure images do not exceed the window width */
}

.pimg1 {
    background-image: url('../img/Image1.jpg');
    min-height: 600px;
}

.pimg3 {
    background-image: url('../img/Image2.jpg');
    min-height: 600px;
}

.pimg6 {
    background-image: url('../img/Image3.jpg');
    min-height: 600px;
}

.pimg2 {
    background-image: url('../img/Image4.jpg');
    min-height: 600px;
}

.pimg4 {
    background-image: url('../img/Image5.jpg');
    min-height: 600px;
}

.pimg5 {
    background-image: url('../img/Image6.jpg');
    min-height: 600px;
}

.section {
    text-align: center;
    padding: 50px 80px;
}

.section-light {
    background-color: #f4f4f4;
    color: #666;
}

.section-dark {
    background-color: #282e34;
    color: #ddd;
}

.ptext {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 27px;
    letter-spacing: 8px;
    text-transform: uppercase;
    transform: translateY(-50%);
}

.ptext .border {
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 20px;
}


.ptext .border.trans {
    background-color: transparent;
}

nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure the navigation bar is above other content */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 10px 0;
}

.nav-container {
    max-width: 1200px; /* Limit the width of the navigation bar */
    margin: 0 auto; /* Center the navigation bar horizontally */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-toggle {
    display: none; /* Hide the toggle button by default */
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.logo {
    position: relative;
    top: 60px; /* Adjust the top position as needed */
    left: center; /* Adjust the left position as needed */
    z-index: 1; /* Ensure the logo appears above the background image */
    opacity: 1; /* Adjust the opacity as needed */
    height:auto;
    width:325px;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around; /* Distribute items evenly */
}

nav ul li {
    margin: 0 10px; /* Add space between navigation items */
}

nav ul li a {
    text-decoration: none;
    color: #fff; /* Set text color to white */
    font-weight: bold;
    padding: 10px 20px; /* Add padding for clickable area */
    transition: background-color 0.3s; /* Smooth background color transition */
}

nav ul li a:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Add a slight hover effect */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .nav-toggle {
        display: block; /* Show the toggle button on small screens */
    }

    .nav-menu {
        display: none; /* Hide the navigation menu by default on small screens */
        flex-direction: column;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8); /* Make the dropdown background a bit darker */
        position: absolute;
        top: 60px; /* Adjust based on the height of the nav bar */
        left: 0;
        z-index: 1000;
    }

    .nav-menu.show {
        display: flex; /* Show the navigation menu when the toggle is clicked */
    }

    nav ul {
        flex-direction: column;
        align-items: center;
    }

    nav ul li {
        margin: 10px 0;
    }

    .ptext {
        font-size: 20px;
        letter-spacing: 4px;
    }

    .section {
        padding: 30px 20px;
    }
    .pimg1, .pimg2, .pimg3, .pimg4, .pimg5, .pimg6 {
        background-attachment: scroll;
    }
    .logo {
        position: relative; /* Change position to relative */
        top: 40px; /* Adjust the top position as needed */
        left: 120px; /* Center the logo horizontally */
        transform: translateX(-50%); /* Center the logo horizontally */
        z-index: 1; /* Ensure the logo appears above the background image */
        opacity: 1; /* Adjust the opacity as needed */
        width: 225px; /* Adjust the width of the logo for smaller screens */
        height: auto; /* Maintain aspect ratio */
    }
}

@media (max-width: 568px) {
    .pimg1, .pimg2, .pimg3, .pimg4, .pimg5, .pimg6 {
        background-attachment: scroll;
        min-height: 400px; /* Adjust height for smaller screens */
    }

    .ptext {
        font-size: 16px;
        letter-spacing: 2px;
    }

    .section {
        padding: 20px 10px;
    }

    nav ul li a {
        padding: 10px;
    }
}
